<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Manage Bookings</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Booking Details</h6>
          </div>
          <div class="card-body">
            <form class="bookingData">
              <fieldset disabled>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>First Name</label>
                    <input type="text" class="form-control" :placeholder="booking.firstName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Last Name</label>
                    <input type="text" class="form-control" :placeholder="booking.lastName">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Email</label>
                    <input type="text" class="form-control" :placeholder="booking.email">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" :placeholder="booking.phoneNumber">
                  </div>
                </div>
                <div class="row">
                   <div class="col-lg-4" v-if="booking">
                    <label>Competition</label>
                    <input type="text" class="form-control" :placeholder="booking.competition.eventName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Selected Days</label>
                    <textarea class="form-control" :value="formatSelectedDays()" rows="4" readonly></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Main Service</label>
                    <input type="text" class="form-control" :placeholder="booking.services?.title || 'N/A'">
                  </div>
                  <div class="col-lg-4" v-if="booking && booking.additionalServices?.length">
                    <label>Additional Services</label>
                    <textarea class="form-control" :value="formatAdditionalServices()" rows="4" readonly></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Dance Style</label>
                    <input type="text" class="form-control" :placeholder="booking.danceStyle">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Dance Level</label>
                    <input type="text" class="form-control" :placeholder="booking.danceLevel">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>If Nadezda is not available...?</label>
                    <input type="text" class="form-control" :placeholder="booking.notAvailable">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Hair the night before?</label>
                    <input type="text" class="form-control" :placeholder="booking.hairAtNight">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8" v-if="booking">
                    <label>Message</label>
                    <textarea rows="4" class="form-control" :placeholder="booking.message" />
                  </div>
                </div>
              </fieldset>
            </form>
            <br />
            <hr>
            <h3>Assign Booking</h3>
            <form @submit.prevent="editBooking" v-if="booking">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="assignedUser">Assign User</label>
                    <select v-model="booking.assignedUser" class="form-control">
                      <option v-for="user in users" :key="user._id" :value="user._id">{{ user.firstName }} {{ user.lastName }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-for="(day, index) in booking.selectedEventDays" :key="index" class="day-slot">
                <h5>{{ formatDate(day) }}</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="startDate">Start Date</label>
                      <input type="date" v-model="startDates[index]" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="startTime">Start Time</label>
                      <input type="time" v-model="startTimes[index]" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endDate">End Date</label>
                      <input type="date" v-model="endDates[index]" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endTime">End Time</label>
                      <input type="time" v-model="endTimes[index]" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <h5>Dancers Scheduled Dance Start Date/Time</h5>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="endDate">Dance Start Date</label>
                    <input type="date" v-model="booking.danceStartDate" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="endTime">Dance Start Time</label>
                    <input type="time" v-model="booking.danceStartTime" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="additionalNotes">Additional Notes</label>
                    <textarea v-model="booking.additionalNotes" class="form-control"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <router-link class="btn btn-primary btn-icon-split" to="/admin/calendar_bookings">
                      <span class="icon text-white-50">
                        <i class="fas fa-chevron-left"></i>
                      </span>
                      <span class="text">Back to Manage Bookings</span>
                    </router-link>
                  </div>
                  <div class="form-group">
                    <button @click="deleteBooking" type="button" class="btn btn-danger btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-trash"></i>
                      </span>
                      <span class="text">Delete Booking</span>
                    </button>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <div class="text-right">
                      <button type="submit" class="btn btn-success btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="text">Save</span>
                      </button>
                      <br />
                      <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                      <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="text-right">
                      <button @click="sendBookingEmail" type="button" class="btn btn-info btn-icon-split">
                        <span class="icon text-white-50">
                          <i class="fas fa-envelope"></i>
                        </span>
                        <span class="text">Send Booking Email</span>
                      </button>
                      <br />
                      <span v-if="contentEmailSent" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Email Sent</span>
                      <span v-if="contentEmailError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Email Sending Error</span>
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <span v-if="contentEmailRequiredDataError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Email Not Sent. Please assign a user and/or date(s) and time(s)</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { fetchBooking, updateBookingDetails, deleteBookingRequest, sendBookingEmail } from '@/utils/booking';
import { fetchUsers } from '@/utils/users';

export default {
  name: 'AdminCalendarBookings',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      users: [],
      booking: null,
      contentSaved: false,
      contentError: false,
      contentEmailSent: false,
      contentEmailError: false,
      contentEmailRequiredDataError: false,
      startDates: [], // Array to hold start dates for each selected event day
      startTimes: [], // Array to hold start times for each selected event day
      endDates: [],   // Array to hold end dates for each selected event day
      endTimes: []    // Array to hold end times for each selected event day
    };
  },
  async created() {
    await this.loadBooking();
    await this.loadUsers();
  },
  methods: {
    async loadBooking() {
      try {
        this.booking = await fetchBooking(this.id);
        
        // Parse start and end date/time if they are stored as JSON strings
        this.startDates = this.booking.startDate ? JSON.parse(this.booking.startDate) : Array(this.booking.selectedEventDays.length).fill('');
        this.startTimes = this.booking.startTime ? JSON.parse(this.booking.startTime) : Array(this.booking.selectedEventDays.length).fill('');
        this.endDates = this.booking.endDate ? JSON.parse(this.booking.endDate) : Array(this.booking.selectedEventDays.length).fill('');
        this.endTimes = this.booking.endTime ? JSON.parse(this.booking.endTime) : Array(this.booking.selectedEventDays.length).fill('');
      } catch (error) {
        console.error('Error fetching booking:', error);
      }
    },
    async loadUsers() {
      this.users = await fetchUsers(false);
    },
    formatDate(dateString) {
      return moment(dateString).format('dddd, MMMM Do YYYY');
    },
    async editBooking() {
      try {
        // Convert start/end date and time arrays to JSON strings for saving
        const updatedBooking = {
          ...this.booking,
          startDate: JSON.stringify(this.startDates),
          startTime: JSON.stringify(this.startTimes),
          endDate: JSON.stringify(this.endDates),
          endTime: JSON.stringify(this.endTimes)
        };
        
        const status = await updateBookingDetails(updatedBooking);

        if (status.update === true) {
          this.contentSaved = true;
          this.contentError = false;
          this.contentEmailRequiredDataError = false;
          
          setTimeout(() => {
            this.contentSaved = false;
          }, 5000);
        } else {
          this.contentSaved = false;
          this.contentError = true;
          this.contentEmailRequiredDataError = false;
          
          setTimeout(() => {
            this.contentError = false;
          }, 5000);
        }
      } catch (error) {
        console.error('Error saving booking:', error);
      }
    },
    async deleteBooking() {
      if (confirm('Are you sure you want to delete this booking?')) {
        try {
          const status = await deleteBookingRequest(this.id);
          if (status.deleted) {
            alert('Booking deleted successfully');
            this.$router.push('/admin/calendar_bookings');
          } else {
            console.log('Error deleting booking');
          }
        } catch (error) {
          console.error('Error deleting booking:', error);
        }
      }
    },
    async sendBookingEmail() {
      
      // Check if a user is assigned and all dates/times are filled
      if (!this.booking.assignedUser) {
        this.contentEmailError = false;
        this.contentEmailRequiredDataError = true;
        this.contentEmailSent = false;

        setTimeout(() => {
          this.contentEmailRequiredDataError = false;
        }, 10000);
        return;
      }

      const allDatesFilled = this.startDates.every(date => date !== '');
      const allStartTimesFilled = this.startTimes.every(time => time !== '');
      const allEndDatesFilled = this.endDates.every(date => date !== '');
      const allEndTimesFilled = this.endTimes.every(time => time !== '');

      if (!allDatesFilled || !allStartTimesFilled || !allEndDatesFilled || !allEndTimesFilled) {
        this.contentEmailError = false;
        this.contentEmailRequiredDataError = true;
        this.contentEmailSent = false;

        setTimeout(() => {
          this.contentEmailRequiredDataError = false;
        }, 10000);
        return;
      }
      
      if (confirm('Are you sure you want to send an email to the customer?')) {
        try {
          const response = await sendBookingEmail(this.id);
          
          if (response.sent === true) {
            this.contentEmailError = false;
            this.contentEmailRequiredDataError = false;
            this.contentEmailSent = true;

            setTimeout(() => {
              this.contentEmailSent = false;
            }, 5000);
          } else {
            this.contentSaved = false;
            this.contentEmailError = true;
            this.contentEmailRequiredDataError = false;

            setTimeout(() => {
              this.contentEmailError = false;
            }, 5000);
          }
        } catch (error) {
          console.error('Error sending booking email:', error);
        }
      }
    },
    formatSelectedDays() {
      if (!this.booking || !this.booking.selectedEventDays) return '';
      return this.booking.selectedEventDays
        .map(dateString => {
          return moment(dateString).format('dddd (MM/DD)');
        })
        .join('\n');
    },
    formatAdditionalServices() {
      return this.booking.additionalServices?.map(service => service.title).join(', ') || 'None';
    }
  }
};
</script>

<style scoped>
.bookingData .row+.row {
    margin-top: 1rem;
}
.day-slot {
  margin-top: 1.5rem;
}
</style>